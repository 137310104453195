<template>
  <CCard class="flow">
    <CCardHeader class="d-flex"
                 v-bind:class="{'with_image' : flowData.image_id}"
                 v-bind:style="flowData.image_id ? 'background-image: url(' + apiBaseUrl + '/v1/common/cdn/file/image/connect-flow/' + flowData.sw_group_id_external + '/' + flowData.image_id + '.jpg' + '/' + clientToken : ''">

      <i v-if="!flowData.image_id" class="fas fa-stream"/>
      <span>{{flowData.sw_group_name}}</span>   
    </CCardHeader>
    <CCardBody v-if="flowData.description">
      <span>{{flowData.description}}</span>    
    </CCardBody>
    <CCardFooter>
      <div class="d-flex align-items-center">
        <div class="flex-grow-1 d-flex align-items-center justify-content-start">
          <b-tag v-if="!flowData.is_active" class="mr-1 inactive">{{ $t('common.Inactive') }}</b-tag>
          <b-tag v-if="flowData.is_followable" class="mr-1 info">{{ $t('connect.Followable') }}</b-tag>
          <b-tag v-if="flowData.default_subtopic" class="detail">{{ flowData.default_subtopic.label }}</b-tag>
        </div>
        <div class="pl-2 pointer headcount" @click="openSidebarRight('headcount_details', { headcount_type: 'flow', headcount_id_external: flowData.sw_group_id_external });">
          <span class="d-flex align-items-center">
            <i class="icon far fa-user"/>{{flowData.headcount.headcount_total}}
          </span>
        </div>
        <div v-if="checkPermission('connect.flows.edit')" class="pl-2 pointer" @click="openSidebarRight('flow_details', { sw_group_id_external: flowData.sw_group_id_external })">
          <span>
            <i class="fas fa-pen"/>
          </span>
        </div>
      </div>
    </CCardFooter>
  </CCard>
</template>

<script>

export default {
  name: 'flowCard',
  props: ['flow', 'platformPermissions'],
  watch: {
    $props: {
      handler() {
        if(this.flow) this.flowData = this.flow;
      },
      deep: true,
      immediate: true,
    }
  },     
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      flowData: {}
    }
  },
  methods: {
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
  }  
}
</script>