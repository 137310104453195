<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('connect.flows.overview')" class="connect">
    <CCol cols="12" lg="12" md="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="5" xl="5" class="pt-0 pb-0 text-left">
              {{$t('sw.Groups')}}
            </CCol>
            <CCol cols="7" xl="7" class="pt-0 pb-0 text-xl-right">
              <b-switch class="mb-0 mt-1 mt-xl-0" v-model="showInactive" size="is-small" @input="getFlows()">{{ $t('common.show_inactive') }}</b-switch>
              <!-- <b-switch class="mb-0 mt-1 mt-xl-0 enable_search" v-model="searchEnabled" size="is-small">{{$t('common.activate_search')}}</b-switch> -->
              <div v-if="checkPermission('connect.flows.add')" class="mt-1 mt-xl-0 d-inline-block align-top">
                <CButton class="ml-2 mr-0 link" color="primary" @click="openSidebarRight('flow_details', { sw_group_id_external: null })">
                  <i class="fas fa-plus mr-1"/>
                  <span>{{$t('sw.Add_group')}}</span>
                </CButton>
              </div> 
            </CCol> 
          </CRow>
        </CCardHeader>
        <CCardBody v-bind:class="{'p-0' : overviewDataLoaded}">                            
          <loadingSpinner mode="auto" v-if="!overviewDataLoaded" :content="$t('sw.Loading_sw_groups')"/>

          <div v-if="overviewDataLoaded && flows.length > 0">
            <CRow class="m-0 flows">
              <CCol v-for="flow in flows" :key="flow.sw_group_id_external" cols="4" xl="4" lg="4" class="p-0">
                <connectFlow :flow="flow" :platformPermissions="platformPermissions"/>
              </CCol>
            </CRow>
            <hr class="m-0">
            <CRow class="m-0">
              <CCol cols="12" md="12">
                <v-pagination class="justify-content-end"
                              v-model="currentFlowsPage"
                              @input="onPageChange"
                              :length="flowsPages"
                              :total-visible="9"
                              prev-icon="mdi-chevron-left"
                              next-icon="mdi-chevron-right">
                </v-pagination>
              </CCol>
            </CRow>            
          </div>
          <div v-if="overviewDataLoaded && flows.length === 0">
            <CRow class="m-0">
              <CCol cols="12" lg="12">
                <span>{{$t('sw.No_groups_found')}}</span>
              </CCol>
            </CRow>                  
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import QuickEdit from 'vue-quick-edit';
import Multiselect from 'vue-multiselect';
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';

import connectFlow from '@/components/connect/connectFlow.vue';

export default {
  name: 'Groups',
  components: {
    QuickEdit,
    Multiselect,
    loadingSpinner,
    noPermission,
    connectFlow
  },
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,
      flows: [],
      overviewDataLoaded: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,
      searchEnabled: true,
      showInactive: false,
      currentFlowsPage: 1,
      currentPageFlows: [],
      flowsPerPage: 6,
      flowsPages: 0,
      flowsStartIndex: 0      
    }
  },
  methods: {
    getFlows() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/flows/overview/' + this.flowsStartIndex)
      .then(res => {
        // Empty the flows array
        this.flows = [];
        // Set the default currentTotal value
        let currentTotal = res.data.data.total_flows;
        // Update the currentTotal value
        if(currentTotal / this.flowsPerPage > 1000) currentTotal = this.flowsPerPage * 1000;        
        // Add the flows to the flows array
        this.flows = res.data.data.flows;
        // Filter the data if necessary
        if(!this.showInactive) this.flows = this.flows.filter(i => ['Y'].includes( i.active ));        
        // Reset the flowsPages value
        this.flowsPages = 0;
        // Define the number of items of the pagination
        for (let i = 0; i < currentTotal; i = i + this.flowsPerPage) {
          this.flowsPages++;
        }
        // Update the overviewDataLoaded value
        this.overviewDataLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupDetails(swGroupIdExternal) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/flow/' + swGroupIdExternal)
      .then(res => {
        let updatedFlow = res.data.data;
        // Get the index of the updated flow
        let flowIndex = this.flows.findIndex(x => x.sw_group_id_external == updatedFlow.sw_group_id_external);
        // Update the flow
        this.flows[flowIndex].sw_group_name = updatedFlow.sw_group_name;
        this.flows[flowIndex].description = updatedFlow.description;
        this.flows[flowIndex].active = updatedFlow.active;
        this.flows[flowIndex].is_active = updatedFlow.is_active;
        this.flows[flowIndex].is_followable = updatedFlow.is_followable;
        this.flows[flowIndex].groups = updatedFlow.groups;
        this.flows[flowIndex].contributors = updatedFlow.contributors;
        this.flows[flowIndex].default_subtopic = updatedFlow.default_subtopic;
        this.flows[flowIndex].headcount = updatedFlow.headcount;
        this.flows[flowIndex].image_id = updatedFlow.image_id;
        // Filter the data if necessary
        if(!this.showInactive) this.flows = this.flows.filter(i => ['Y'].includes( i.active ));
        // Force update
        this.$forceUpdate();
      })
      .catch(err => {
        console.error(err); 
      });
    },        
    updateGroup(swGroupIdExternal, data) {
      let params = {}
      params = data;
      (params.is_active === true) ? params.active = 'Y' : params.active = 'N';
      (params.is_followable === true) ? params.followable = 'Y' : params.followable = 'N';

      let sw_group_name = params.sw_group_name;     

      if(sw_group_name) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/connect/flow/' + swGroupIdExternal, params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('sw.Social_wall_group_updated'), type: 'is-success', duration: 2000 });
          // Update the group details
          this.getGroupDetails(swGroupIdExternal);
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }            
    },
    setPagination() {
      // Set the pagination to the first page
      this.currentFlowsPage = 1;
      // Reset the flowsPages value
      this.flowsPages = 0;
      // Loop througb the headcountDetails to divide the data in pages         
      for (let i = 0; i < this.flows.length; i = i + this.flowsPerPage) {
        this.currentPageFlows[this.flowsPages] = this.flows.slice(i, i + this.flowsPerPage);
        this.flowsPages++;
      }
    },        
    onPageChange(page) {
      // Set the flowsStartIndex value
      (page > 1) ? this.flowsStartIndex = (page - 1) * this.flowsPerPage : this.flowsStartIndex = 0;
      // Get the flows
      this.getFlows();
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }
  },
  mounted: function(){
    this.getFlows();
    this.getPlatformPermissions();

    this.$bus.$on('update_flows', () => {
      this.getFlows();
    });

    this.$bus.$on('update_flow_details', (swGroupIdExternal) => {
      this.getGroupDetails(swGroupIdExternal);
    });    
  },
  beforeDestroy() {
    this.$bus.$off('update_flows');
    this.$bus.$off('update_flow_details');
  }
}
</script>